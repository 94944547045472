import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1> 
  </div> 
)

export default NotFoundPage
